// source: list.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.da.calendar.proto.ArchiveListRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.ArchiveListResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.CreateListRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.CreateListResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.DeleteListRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.DeleteListResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.List', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadListRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadListResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadListsByProjectIdRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadListsByProjectIdResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadListsRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadListsResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.UpdateListRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.UpdateListResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.List.displayName = 'proto.da.calendar.proto.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.CreateListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.CreateListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.CreateListRequest.displayName = 'proto.da.calendar.proto.CreateListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.CreateListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.CreateListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.CreateListResponse.displayName = 'proto.da.calendar.proto.CreateListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ReadListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadListRequest.displayName = 'proto.da.calendar.proto.ReadListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ReadListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadListResponse.displayName = 'proto.da.calendar.proto.ReadListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.UpdateListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.UpdateListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.UpdateListRequest.displayName = 'proto.da.calendar.proto.UpdateListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.UpdateListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.UpdateListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.UpdateListResponse.displayName = 'proto.da.calendar.proto.UpdateListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadListsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ReadListsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadListsRequest.displayName = 'proto.da.calendar.proto.ReadListsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadListsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.calendar.proto.ReadListsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.calendar.proto.ReadListsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadListsResponse.displayName = 'proto.da.calendar.proto.ReadListsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.DeleteListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.DeleteListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.DeleteListRequest.displayName = 'proto.da.calendar.proto.DeleteListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.DeleteListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.DeleteListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.DeleteListResponse.displayName = 'proto.da.calendar.proto.DeleteListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ArchiveListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ArchiveListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ArchiveListRequest.displayName = 'proto.da.calendar.proto.ArchiveListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ArchiveListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ArchiveListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ArchiveListResponse.displayName = 'proto.da.calendar.proto.ArchiveListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ReadListsByProjectIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadListsByProjectIdRequest.displayName = 'proto.da.calendar.proto.ReadListsByProjectIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.calendar.proto.ReadListsByProjectIdResponse.repeatedFields_, null);
};
goog.inherits(proto.da.calendar.proto.ReadListsByProjectIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadListsByProjectIdResponse.displayName = 'proto.da.calendar.proto.ReadListsByProjectIdResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.List.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.List;
  return proto.da.calendar.proto.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.List.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.List} returns this
 */
proto.da.calendar.proto.List.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.da.calendar.proto.List.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.List} returns this
 */
proto.da.calendar.proto.List.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.da.calendar.proto.List.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.List} returns this
 */
proto.da.calendar.proto.List.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string project_id = 4;
 * @return {string}
 */
proto.da.calendar.proto.List.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.List} returns this
 */
proto.da.calendar.proto.List.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.CreateListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.CreateListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.CreateListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    list: (f = msg.getList()) && proto.da.calendar.proto.List.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.CreateListRequest}
 */
proto.da.calendar.proto.CreateListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.CreateListRequest;
  return proto.da.calendar.proto.CreateListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.CreateListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.CreateListRequest}
 */
proto.da.calendar.proto.CreateListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.List;
      reader.readMessage(value,proto.da.calendar.proto.List.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.CreateListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.CreateListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.CreateListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.List.serializeBinaryToWriter
    );
  }
};


/**
 * optional List list = 1;
 * @return {?proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.CreateListRequest.prototype.getList = function() {
  return /** @type{?proto.da.calendar.proto.List} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.List, 1));
};


/**
 * @param {?proto.da.calendar.proto.List|undefined} value
 * @return {!proto.da.calendar.proto.CreateListRequest} returns this
*/
proto.da.calendar.proto.CreateListRequest.prototype.setList = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.CreateListRequest} returns this
 */
proto.da.calendar.proto.CreateListRequest.prototype.clearList = function() {
  return this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.CreateListRequest.prototype.hasList = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.CreateListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.CreateListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.CreateListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    list: (f = msg.getList()) && proto.da.calendar.proto.List.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.CreateListResponse}
 */
proto.da.calendar.proto.CreateListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.CreateListResponse;
  return proto.da.calendar.proto.CreateListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.CreateListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.CreateListResponse}
 */
proto.da.calendar.proto.CreateListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.List;
      reader.readMessage(value,proto.da.calendar.proto.List.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.CreateListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.CreateListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.CreateListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.List.serializeBinaryToWriter
    );
  }
};


/**
 * optional List list = 1;
 * @return {?proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.CreateListResponse.prototype.getList = function() {
  return /** @type{?proto.da.calendar.proto.List} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.List, 1));
};


/**
 * @param {?proto.da.calendar.proto.List|undefined} value
 * @return {!proto.da.calendar.proto.CreateListResponse} returns this
*/
proto.da.calendar.proto.CreateListResponse.prototype.setList = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.CreateListResponse} returns this
 */
proto.da.calendar.proto.CreateListResponse.prototype.clearList = function() {
  return this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.CreateListResponse.prototype.hasList = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadListRequest}
 */
proto.da.calendar.proto.ReadListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadListRequest;
  return proto.da.calendar.proto.ReadListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadListRequest}
 */
proto.da.calendar.proto.ReadListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.ReadListRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.ReadListRequest} returns this
 */
proto.da.calendar.proto.ReadListRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    list: (f = msg.getList()) && proto.da.calendar.proto.List.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadListResponse}
 */
proto.da.calendar.proto.ReadListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadListResponse;
  return proto.da.calendar.proto.ReadListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadListResponse}
 */
proto.da.calendar.proto.ReadListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.List;
      reader.readMessage(value,proto.da.calendar.proto.List.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.List.serializeBinaryToWriter
    );
  }
};


/**
 * optional List list = 1;
 * @return {?proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.ReadListResponse.prototype.getList = function() {
  return /** @type{?proto.da.calendar.proto.List} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.List, 1));
};


/**
 * @param {?proto.da.calendar.proto.List|undefined} value
 * @return {!proto.da.calendar.proto.ReadListResponse} returns this
*/
proto.da.calendar.proto.ReadListResponse.prototype.setList = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.ReadListResponse} returns this
 */
proto.da.calendar.proto.ReadListResponse.prototype.clearList = function() {
  return this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.ReadListResponse.prototype.hasList = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.UpdateListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.UpdateListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.UpdateListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    list: (f = msg.getList()) && proto.da.calendar.proto.List.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.UpdateListRequest}
 */
proto.da.calendar.proto.UpdateListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.UpdateListRequest;
  return proto.da.calendar.proto.UpdateListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.UpdateListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.UpdateListRequest}
 */
proto.da.calendar.proto.UpdateListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.List;
      reader.readMessage(value,proto.da.calendar.proto.List.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.UpdateListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.UpdateListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.UpdateListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.List.serializeBinaryToWriter
    );
  }
};


/**
 * optional List list = 1;
 * @return {?proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.UpdateListRequest.prototype.getList = function() {
  return /** @type{?proto.da.calendar.proto.List} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.List, 1));
};


/**
 * @param {?proto.da.calendar.proto.List|undefined} value
 * @return {!proto.da.calendar.proto.UpdateListRequest} returns this
*/
proto.da.calendar.proto.UpdateListRequest.prototype.setList = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.UpdateListRequest} returns this
 */
proto.da.calendar.proto.UpdateListRequest.prototype.clearList = function() {
  return this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.UpdateListRequest.prototype.hasList = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.UpdateListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.UpdateListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.UpdateListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    list: (f = msg.getList()) && proto.da.calendar.proto.List.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.UpdateListResponse}
 */
proto.da.calendar.proto.UpdateListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.UpdateListResponse;
  return proto.da.calendar.proto.UpdateListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.UpdateListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.UpdateListResponse}
 */
proto.da.calendar.proto.UpdateListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.List;
      reader.readMessage(value,proto.da.calendar.proto.List.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.UpdateListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.UpdateListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.UpdateListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.List.serializeBinaryToWriter
    );
  }
};


/**
 * optional List list = 1;
 * @return {?proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.UpdateListResponse.prototype.getList = function() {
  return /** @type{?proto.da.calendar.proto.List} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.List, 1));
};


/**
 * @param {?proto.da.calendar.proto.List|undefined} value
 * @return {!proto.da.calendar.proto.UpdateListResponse} returns this
*/
proto.da.calendar.proto.UpdateListResponse.prototype.setList = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.UpdateListResponse} returns this
 */
proto.da.calendar.proto.UpdateListResponse.prototype.clearList = function() {
  return this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.UpdateListResponse.prototype.hasList = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadListsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadListsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadListsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadListsRequest}
 */
proto.da.calendar.proto.ReadListsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadListsRequest;
  return proto.da.calendar.proto.ReadListsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadListsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadListsRequest}
 */
proto.da.calendar.proto.ReadListsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadListsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadListsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadListsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.da.calendar.proto.ReadListsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadListsRequest} returns this
 */
proto.da.calendar.proto.ReadListsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 per_page = 2;
 * @return {number}
 */
proto.da.calendar.proto.ReadListsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadListsRequest} returns this
 */
proto.da.calendar.proto.ReadListsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string project_id = 3;
 * @return {string}
 */
proto.da.calendar.proto.ReadListsRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.ReadListsRequest} returns this
 */
proto.da.calendar.proto.ReadListsRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.calendar.proto.ReadListsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadListsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadListsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadListsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listsList: jspb.Message.toObjectList(msg.getListsList(),
    proto.da.calendar.proto.List.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadListsResponse}
 */
proto.da.calendar.proto.ReadListsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadListsResponse;
  return proto.da.calendar.proto.ReadListsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadListsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadListsResponse}
 */
proto.da.calendar.proto.ReadListsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.List;
      reader.readMessage(value,proto.da.calendar.proto.List.deserializeBinaryFromReader);
      msg.addLists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadListsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadListsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadListsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.calendar.proto.List.serializeBinaryToWriter
    );
  }
};


/**
 * repeated List lists = 1;
 * @return {!Array<!proto.da.calendar.proto.List>}
 */
proto.da.calendar.proto.ReadListsResponse.prototype.getListsList = function() {
  return /** @type{!Array<!proto.da.calendar.proto.List>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.calendar.proto.List, 1));
};


/**
 * @param {!Array<!proto.da.calendar.proto.List>} value
 * @return {!proto.da.calendar.proto.ReadListsResponse} returns this
*/
proto.da.calendar.proto.ReadListsResponse.prototype.setListsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.calendar.proto.List=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.ReadListsResponse.prototype.addLists = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.calendar.proto.List, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.calendar.proto.ReadListsResponse} returns this
 */
proto.da.calendar.proto.ReadListsResponse.prototype.clearListsList = function() {
  return this.setListsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.DeleteListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.DeleteListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.DeleteListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.DeleteListRequest}
 */
proto.da.calendar.proto.DeleteListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.DeleteListRequest;
  return proto.da.calendar.proto.DeleteListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.DeleteListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.DeleteListRequest}
 */
proto.da.calendar.proto.DeleteListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.DeleteListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.DeleteListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.DeleteListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.DeleteListRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.DeleteListRequest} returns this
 */
proto.da.calendar.proto.DeleteListRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.DeleteListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.DeleteListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.DeleteListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.DeleteListResponse}
 */
proto.da.calendar.proto.DeleteListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.DeleteListResponse;
  return proto.da.calendar.proto.DeleteListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.DeleteListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.DeleteListResponse}
 */
proto.da.calendar.proto.DeleteListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.DeleteListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.DeleteListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.DeleteListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.DeleteListResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.DeleteListResponse} returns this
 */
proto.da.calendar.proto.DeleteListResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ArchiveListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ArchiveListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ArchiveListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ArchiveListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ArchiveListRequest}
 */
proto.da.calendar.proto.ArchiveListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ArchiveListRequest;
  return proto.da.calendar.proto.ArchiveListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ArchiveListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ArchiveListRequest}
 */
proto.da.calendar.proto.ArchiveListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ArchiveListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ArchiveListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ArchiveListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ArchiveListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.ArchiveListRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.ArchiveListRequest} returns this
 */
proto.da.calendar.proto.ArchiveListRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ArchiveListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ArchiveListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ArchiveListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ArchiveListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ArchiveListResponse}
 */
proto.da.calendar.proto.ArchiveListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ArchiveListResponse;
  return proto.da.calendar.proto.ArchiveListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ArchiveListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ArchiveListResponse}
 */
proto.da.calendar.proto.ArchiveListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ArchiveListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ArchiveListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ArchiveListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ArchiveListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.ArchiveListResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.ArchiveListResponse} returns this
 */
proto.da.calendar.proto.ArchiveListResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadListsByProjectIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdRequest}
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadListsByProjectIdRequest;
  return proto.da.calendar.proto.ReadListsByProjectIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdRequest}
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadListsByProjectIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdRequest} returns this
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 page = 2;
 * @return {number}
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdRequest} returns this
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 per_page = 3;
 * @return {number}
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdRequest} returns this
 */
proto.da.calendar.proto.ReadListsByProjectIdRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadListsByProjectIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listsList: jspb.Message.toObjectList(msg.getListsList(),
    proto.da.calendar.proto.List.toObject, includeInstance),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdResponse}
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadListsByProjectIdResponse;
  return proto.da.calendar.proto.ReadListsByProjectIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdResponse}
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.List;
      reader.readMessage(value,proto.da.calendar.proto.List.deserializeBinaryFromReader);
      msg.addLists(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadListsByProjectIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.calendar.proto.List.serializeBinaryToWriter
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated List lists = 1;
 * @return {!Array<!proto.da.calendar.proto.List>}
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.getListsList = function() {
  return /** @type{!Array<!proto.da.calendar.proto.List>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.calendar.proto.List, 1));
};


/**
 * @param {!Array<!proto.da.calendar.proto.List>} value
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdResponse} returns this
*/
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.setListsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.calendar.proto.List=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.calendar.proto.List}
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.addLists = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.calendar.proto.List, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdResponse} returns this
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.clearListsList = function() {
  return this.setListsList([]);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.ReadListsByProjectIdResponse} returns this
 */
proto.da.calendar.proto.ReadListsByProjectIdResponse.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.da.calendar.proto);
