import React, { ReactNode, useContext } from "react";
import { FormControl, HStack, SelectProps } from "@chakra-ui/react";
import { FormContext } from "./FormContainer";
import ErrorContainer from "../ErrorContainer";
import { Select } from "antd";
import { borderColor } from "../../../theme/theme";

interface Props<T> extends SelectProps {
	name: string;
	optionalCallback?: (id: string) => void;
	setState?: React.Dispatch<React.SetStateAction<boolean>>;
	stateString?: string;
	setValue?: React.Dispatch<React.SetStateAction<any[]>>;
	action?: ReactNode;
	defaultValue?: any;
	withAction?: boolean;
	[key: string]: any;
}

function FormMultiSelect<T>({
	optionalCallback,
	setValue,
	action,
	name,
	placeholder,
	isRequired,
	children,
	setState,
	stateString,
	defaultValue,
	withAction,
	handleSearch,
	...rest
}: Props<T>) {
	const formContext = useContext(FormContext);
	const { register, errors, setValue: setField } = formContext;
	if (register == null && !rest.register) {
		throw new Error(
			"This component must be used in the context of a <FormContainer> component"
		);
	}
	const handleOnChange = (values: number[]) => {
		if (setState) {
			setState(true);
		} else if (setField) {
			setField(name, values);
		}

		if (setValue) {
			setValue(values);
		}

		if (optionalCallback) {
			optionalCallback(stateString ?? "");
		}
	};

	const handleOnSearch = (value: string) => {
		handleSearch && handleSearch(value);
	};

	return (
		<ErrorContainer error={errors[name]}>
			<FormControl isRequired={isRequired}>
				<HStack>
					<Select
						mode={withAction ? "tags" : "multiple"}
						style={{
							width: "100%",
							backgroundColor: "transparent",
							borderRadius: "3px",
							flexGrow: 1,
							border: `1px solid ${borderColor}`,
						}}
						placeholder={placeholder}
						onChange={handleOnChange}
						maxTagCount={"responsive"}
						defaultValue={defaultValue}
						filterOption={(input, option) =>
							JSON.stringify(option?.children ?? "")
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						onSearch={handleOnSearch}
					>
						{children}
					</Select>
					{action}
				</HStack>
			</FormControl>
		</ErrorContainer>
	);
}

export default FormMultiSelect;
