import { useDisclosure } from "@chakra-ui/react";
import { FcSurvey } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import strings from "../../../localization/main";
import { CallDispositionDataType } from "../../../types/DataTypes";
import CustomButton from "../../buttons/CustomButton";
import DispositionButton from "../../phone/DispositionButton";
import MyModal from "../../general/MyModal";
import SurveyResponseList from "./SurveyResponseList";

/*
    This component is used to add a new response to the survey by dispositioning a phone call.
*/
interface Props {
  isDisposition?: boolean;
  disposition?: CallDispositionDataType;
  activeCall?: boolean;
}
export default function AddSurvey({
  isDisposition,
  activeCall,
  disposition,
}: Props) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return isDisposition ? (
    <>
      <DispositionButton
        disposition={{
          ...disposition!,
          onclick: onOpen,
        }}
        permissionAvailable
        activeCall={activeCall as boolean}
      />
      <MyModal
        size={"xl"}
        disclosure={{ isOpen, onOpen, onClose }}
        title={strings.survey}
        closeOnOverlayClick={true}
      >
        <SurveyResponseList onClose={onClose} />
      </MyModal>
    </>
  ) : (
      <CustomButton onClick={() => navigate("add_survey")}>
        <FcSurvey />
        {strings.add_survey}
      </CustomButton>
  );
}
