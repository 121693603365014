import { Box, HStack, Divider, VStack } from "@chakra-ui/react";
import { useDrop } from "react-dnd";
import { SubHeading } from "../../headings/Headings";
import { useDispatch } from "react-redux";
import { notify } from "../../../gateway/Functions";
import { updateTables } from "../../../redux/actions/actions";
import strings from "../../../localization/main";
import { borderColor, primaryWhite } from "../../../theme/theme";
import { updateTaskList } from "../../../gateway/gRPC/Utils/CalendarMethods";
import ListBoardEntry from "./ListBoardEntry";
import { Task } from "../../../gateway/gRPC/pb/task_pb";
import AddTask from "../tasks/AddTask";
import { Tag } from "antd";

interface IListoardStageProps {
    stage: any
    tasks: any[]
}

function ListBoardStage({ stage, tasks }: Readonly<IListoardStageProps>) {
    const dispatch = useDispatch();
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "task_entry",
        drop: (item: any) => {
            changeTaskStage(item?.task)
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const changeTaskStage = (task: Task.AsObject) => {
        task.listId = stage?.id
        updateTaskList({
            taskId: task.id,
            listId: task.listId
        })
            .then((res: any) => {
                dispatch(updateTables());
                notify(
                    "success",
                    "bottomLeft",
                    strings.success,
                    `Task updated successfully`
                );
            })
            .catch((err: any) => {
                notify("error", "bottomLeft", "Error", `Task stage could not be updated`);
            });

    };
    return (
        <VStack
            key={stage?.id}
            ref={drop}
            p={"1rem"}
            bg={primaryWhite}
            border={`1px solid ${borderColor}}`}
            align={"flex-start"}
            style={{ height: "min-content" }}
        >
            <HStack justifyContent={"space-between"}>
                <SubHeading>{stage?.title} </SubHeading>
                <Tag
                    style={{ borderRadius: "3px" }}
                >
                    {tasks?.length}
                </Tag>
            </HStack>
            <Divider my={".5rem"} />
            {tasks?.map((task: any) => {
                return <ListBoardEntry task={task} key={task?.id} />;
            })}
            <Box>
                <AddTask listId={stage?.id} fromBoardView={true}/> {/*TODO: Add AddListIdProp*/}
            </Box>
        </VStack>

    );
}
export default ListBoardStage;