/**
 * @fileoverview gRPC-Web generated client stub for da.calendar.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: calendar_label.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.calendar = {};
proto.da.calendar.proto = require('./calendar_label_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.CalendarLabelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.CalendarLabelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.CreateCalendarLabelRequest,
 *   !proto.da.calendar.proto.CreateCalendarLabelResponse>}
 */
const methodDescriptor_CalendarLabelService_CreateCalendarLabel = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.CalendarLabelService/CreateCalendarLabel',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.CreateCalendarLabelRequest,
  proto.da.calendar.proto.CreateCalendarLabelResponse,
  /**
   * @param {!proto.da.calendar.proto.CreateCalendarLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.CreateCalendarLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.CreateCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.CreateCalendarLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.CreateCalendarLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.CalendarLabelServiceClient.prototype.createCalendarLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/CreateCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_CreateCalendarLabel,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.CreateCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.CreateCalendarLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.CalendarLabelServicePromiseClient.prototype.createCalendarLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/CreateCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_CreateCalendarLabel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadCalendarLabelRequest,
 *   !proto.da.calendar.proto.ReadCalendarLabelResponse>}
 */
const methodDescriptor_CalendarLabelService_ReadCalendarLabel = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.CalendarLabelService/ReadCalendarLabel',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadCalendarLabelRequest,
  proto.da.calendar.proto.ReadCalendarLabelResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadCalendarLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadCalendarLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadCalendarLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadCalendarLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.CalendarLabelServiceClient.prototype.readCalendarLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/ReadCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_ReadCalendarLabel,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadCalendarLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.CalendarLabelServicePromiseClient.prototype.readCalendarLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/ReadCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_ReadCalendarLabel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadCalendarLabelsRequest,
 *   !proto.da.calendar.proto.ReadCalendarLabelsResponse>}
 */
const methodDescriptor_CalendarLabelService_ReadCalendarLabels = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.CalendarLabelService/ReadCalendarLabels',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadCalendarLabelsRequest,
  proto.da.calendar.proto.ReadCalendarLabelsResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadCalendarLabelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadCalendarLabelsResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadCalendarLabelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadCalendarLabelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.CalendarLabelServiceClient.prototype.readCalendarLabels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/ReadCalendarLabels',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_ReadCalendarLabels,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadCalendarLabelsResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.CalendarLabelServicePromiseClient.prototype.readCalendarLabels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/ReadCalendarLabels',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_ReadCalendarLabels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.UpdateCalendarLabelRequest,
 *   !proto.da.calendar.proto.UpdateCalendarLabelResponse>}
 */
const methodDescriptor_CalendarLabelService_UpdateCalendarLabel = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.CalendarLabelService/UpdateCalendarLabel',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.UpdateCalendarLabelRequest,
  proto.da.calendar.proto.UpdateCalendarLabelResponse,
  /**
   * @param {!proto.da.calendar.proto.UpdateCalendarLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.UpdateCalendarLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.UpdateCalendarLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.UpdateCalendarLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.CalendarLabelServiceClient.prototype.updateCalendarLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/UpdateCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_UpdateCalendarLabel,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.UpdateCalendarLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.CalendarLabelServicePromiseClient.prototype.updateCalendarLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/UpdateCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_UpdateCalendarLabel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.DeleteCalendarLabelRequest,
 *   !proto.da.calendar.proto.DeleteCalendarLabelResponse>}
 */
const methodDescriptor_CalendarLabelService_DeleteCalendarLabel = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.CalendarLabelService/DeleteCalendarLabel',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.DeleteCalendarLabelRequest,
  proto.da.calendar.proto.DeleteCalendarLabelResponse,
  /**
   * @param {!proto.da.calendar.proto.DeleteCalendarLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.DeleteCalendarLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.DeleteCalendarLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.DeleteCalendarLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.CalendarLabelServiceClient.prototype.deleteCalendarLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/DeleteCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_DeleteCalendarLabel,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.DeleteCalendarLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.CalendarLabelServicePromiseClient.prototype.deleteCalendarLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.CalendarLabelService/DeleteCalendarLabel',
      request,
      metadata || {},
      methodDescriptor_CalendarLabelService_DeleteCalendarLabel);
};


module.exports = proto.da.calendar.proto;

