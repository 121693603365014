/**
 * @fileoverview gRPC-Web generated client stub for da.calendar.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: list.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.calendar = {};
proto.da.calendar.proto = require('./list_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.ListServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.ListServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.CreateListRequest,
 *   !proto.da.calendar.proto.CreateListResponse>}
 */
const methodDescriptor_ListService_CreateList = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ListService/CreateList',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.CreateListRequest,
  proto.da.calendar.proto.CreateListResponse,
  /**
   * @param {!proto.da.calendar.proto.CreateListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.CreateListResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.CreateListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.CreateListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.CreateListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ListServiceClient.prototype.createList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ListService/CreateList',
      request,
      metadata || {},
      methodDescriptor_ListService_CreateList,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.CreateListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.CreateListResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ListServicePromiseClient.prototype.createList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ListService/CreateList',
      request,
      metadata || {},
      methodDescriptor_ListService_CreateList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadListRequest,
 *   !proto.da.calendar.proto.ReadListResponse>}
 */
const methodDescriptor_ListService_ReadList = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ListService/ReadList',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadListRequest,
  proto.da.calendar.proto.ReadListResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadListResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ListServiceClient.prototype.readList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ListService/ReadList',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadList,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadListResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ListServicePromiseClient.prototype.readList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ListService/ReadList',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.UpdateListRequest,
 *   !proto.da.calendar.proto.UpdateListResponse>}
 */
const methodDescriptor_ListService_UpdateList = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ListService/UpdateList',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.UpdateListRequest,
  proto.da.calendar.proto.UpdateListResponse,
  /**
   * @param {!proto.da.calendar.proto.UpdateListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.UpdateListResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.UpdateListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.UpdateListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.UpdateListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ListServiceClient.prototype.updateList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ListService/UpdateList',
      request,
      metadata || {},
      methodDescriptor_ListService_UpdateList,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.UpdateListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.UpdateListResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ListServicePromiseClient.prototype.updateList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ListService/UpdateList',
      request,
      metadata || {},
      methodDescriptor_ListService_UpdateList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadListsRequest,
 *   !proto.da.calendar.proto.ReadListsResponse>}
 */
const methodDescriptor_ListService_ReadLists = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ListService/ReadLists',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadListsRequest,
  proto.da.calendar.proto.ReadListsResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadListsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadListsResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadListsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadListsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadListsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ListServiceClient.prototype.readLists =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ListService/ReadLists',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadLists,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadListsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadListsResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ListServicePromiseClient.prototype.readLists =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ListService/ReadLists',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadLists);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.DeleteListRequest,
 *   !proto.da.calendar.proto.DeleteListResponse>}
 */
const methodDescriptor_ListService_DeleteList = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ListService/DeleteList',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.DeleteListRequest,
  proto.da.calendar.proto.DeleteListResponse,
  /**
   * @param {!proto.da.calendar.proto.DeleteListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.DeleteListResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.DeleteListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.DeleteListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.DeleteListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ListServiceClient.prototype.deleteList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ListService/DeleteList',
      request,
      metadata || {},
      methodDescriptor_ListService_DeleteList,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.DeleteListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.DeleteListResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ListServicePromiseClient.prototype.deleteList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ListService/DeleteList',
      request,
      metadata || {},
      methodDescriptor_ListService_DeleteList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ArchiveListRequest,
 *   !proto.da.calendar.proto.ArchiveListResponse>}
 */
const methodDescriptor_ListService_ArchiveList = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ListService/ArchiveList',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ArchiveListRequest,
  proto.da.calendar.proto.ArchiveListResponse,
  /**
   * @param {!proto.da.calendar.proto.ArchiveListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ArchiveListResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ArchiveListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ArchiveListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ArchiveListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ListServiceClient.prototype.archiveList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ListService/ArchiveList',
      request,
      metadata || {},
      methodDescriptor_ListService_ArchiveList,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ArchiveListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ArchiveListResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ListServicePromiseClient.prototype.archiveList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ListService/ArchiveList',
      request,
      metadata || {},
      methodDescriptor_ListService_ArchiveList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadListsByProjectIdRequest,
 *   !proto.da.calendar.proto.ReadListsByProjectIdResponse>}
 */
const methodDescriptor_ListService_ReadListsByProjectId = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ListService/ReadListsByProjectId',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadListsByProjectIdRequest,
  proto.da.calendar.proto.ReadListsByProjectIdResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadListsByProjectIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadListsByProjectIdResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadListsByProjectIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadListsByProjectIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ListServiceClient.prototype.readListsByProjectId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ListService/ReadListsByProjectId',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadListsByProjectId,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadListsByProjectIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadListsByProjectIdResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ListServicePromiseClient.prototype.readListsByProjectId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ListService/ReadListsByProjectId',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadListsByProjectId);
};


module.exports = proto.da.calendar.proto;

