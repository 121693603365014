import { Flex, HStack, SimpleGrid, Spacer, VStack } from "@chakra-ui/react";
import { primaryWhite } from "../../../theme/theme";
import FormContainer from "../../forms/FormContainer/FormContainer";
import { SubmitHandler } from "react-hook-form";
import FormInput from "../../forms/FormContainer/FormInput";
import FormTextArea from "../../forms/FormContainer/FormTextArea";
import { SubHeading } from "../../headings/Headings";
import FormCancelButton from "../../buttons/FormCancelButton";
import CustomButton from "../../buttons/CustomButton";
import { MdSave } from "react-icons/md";
import FormSelect from "../../forms/FormContainer/FormSelect";
import { Select } from "antd";

interface FormProps {
    onSubmit: SubmitHandler<any>;
    onClose: () => void;
    loading?: boolean;
    initialValues?: any;
    title: string;
}

const ProjectForm = ({
    onSubmit,
    onClose,
    loading,
    title,
    initialValues
}: FormProps) => {
    return (
        <FormContainer
            onSubmit={onSubmit}
            columns={1}
            initialValues={initialValues}
        >
            <VStack
                gap={".5rem"}
                w={"100%"}
                bg={primaryWhite}
                p={".5rem"}
                border={`1px solid #e8e8e8`}
                borderRadius={"3px"}
                justifyContent={"flex-start"}
                alignItems={"stretch"}
            >
                <HStack>
                    <SimpleGrid
                        templateColumns={"auto 1fr"}
                        placeItems={"center"}
                        gap={".5rem"}
                    >
                        <SubHeading title="Project" />
                    </SimpleGrid>
                    <Spacer />
                </HStack>
                <SimpleGrid columns={1} spacing={"1rem"}>
                    <FormInput
                        name={"title"}
                        placeholder={"project title"}
                        label="project title"
                    />
                    <FormSelect
                        name="visibility">
                        <Select.Option value="private" >
                            {"private"}
                        </Select.Option>
                        <Select.Option value="members">
                            {"members"}
                        </Select.Option>
                        <Select.Option value="public">
                            {"public"}
                        </Select.Option>
                    </FormSelect>
                </SimpleGrid>

                <SubHeading title="description" />
                <FormTextArea
                    name={"description"}
                    isRequired={true}
                    rows={5}
                    placeholder={""}
                />

            </VStack>

            <Flex justify={"flex-end"} align={"center"} mb={"1rem"}>
                <FormCancelButton onClick={onClose} />
                <CustomButton type={"submit"} isLoading={loading}>
                    <MdSave />
                    {title}
                </CustomButton>
            </Flex>
        </FormContainer>
    );
}

export default ProjectForm;