import { FormControl, InputProps } from "@chakra-ui/react";
import { DatePicker } from "antd/es";
import React, { useContext } from "react";
import { borderColor } from "../../../theme/theme";
import ErrorContainer from "../ErrorContainer";
import { FormContext } from "./FormContainer";

interface Props extends InputProps {
  name: string;
  placeholder: string;
  showTime?: boolean;
  setDate?: React.Dispatch<React.SetStateAction<string>>;
  [key: string]: any;
}

function FormInputDate({ name, placeholder, setDate, showTime }: Props) {
  const formContext = useContext(FormContext);
  const { initialValues, register, errors, hover, setValue } = formContext;
  if (register == null) {
    throw new Error(
      "This component must be used in the context of a <FormContainer> component"
    );
  }
  return (
    <ErrorContainer error={errors[name]}>
      <FormControl>
        <DatePicker
          showTime={showTime}
          placeholder={placeholder}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            borderRadius: "3px",
            border: `1px solid ${borderColor}`,
          }}
          onChange={(date, dateString) => {
            setValue && setValue(name, dateString);
            setDate && setDate(dateString);
          }}
        />
      </FormControl>
    </ErrorContainer>
  );
}

export default FormInputDate;
