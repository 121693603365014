import { Flex } from "@chakra-ui/react";
import { Spin } from "antd";

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  key?: string | number;
  [key: string]: any;
}
const PageContainer = ({ children, loading,key, ...rest }: Props) => {
  return (
    <Spin spinning={loading ?? false}>
      <Flex
        w={"100%"}
        h={"100%"}
        flexFlow={"column"}
        {...rest}
        gap={".5rem"}
        key={key??""}
      >
        {children}
      </Flex>
    </Spin>
  );
};

export default PageContainer;
