import { VStack, HStack, SimpleGrid, Spacer, Flex, Text } from "@chakra-ui/react"
import { Select } from "antd"
import { MdSave } from "react-icons/md"
import { primaryWhite } from "../../../theme/theme"
import CustomButton from "../../buttons/CustomButton"
import FormCancelButton from "../../buttons/FormCancelButton"
import FormContainer from "../../forms/FormContainer/FormContainer"
import FormInput from "../../forms/FormContainer/FormInput"
import FormSelect from "../../forms/FormContainer/FormSelect"
import FormTextArea from "../../forms/FormContainer/FormTextArea"
import strings from "../../../localization/main"
import FormInputDate from "../../forms/FormContainer/FormInputDate"
import FormMultiSelect from "../../forms/FormContainer/FormMultiSelect"
import { useEffect, useState } from "react"
import { readCalendarLabels, readListsByProjectId } from "../../../gateway/gRPC/Utils/CalendarMethods"
import { gRPCReadUsers } from "../../../gateway/gRPC/Utils/OrgMethods"
import { SubmitHandler } from "react-hook-form"
import { Task } from "../../../gateway/gRPC/pb/task_pb"

interface FormProps {
    onSubmit: SubmitHandler<Task.AsObject>;
    onClose: () => void;
    loading?: boolean;
    initialValues?: any;
    title: string;
    setSelectedLabels: React.Dispatch<React.SetStateAction<any[]>>;
    setSelectedAgents: React.Dispatch<React.SetStateAction<any[]>>;
    fromTableView?: boolean;
    projectId?: string;
}

const TaskForm = ({
    onSubmit,
    onClose,
    loading,
    initialValues,
    title,
    setSelectedLabels,
    setSelectedAgents,
    fromTableView,
    projectId,
}: FormProps) => {
    const gridColumns = { base: 1, lg: 2 };
    const gridSpacing = ".5rem";
    const [labels, setLabels] = useState<any[]>([]);
    const [agents, setAgents] = useState<any[]>([]);
    const [lists, setLists] = useState<any[]>([]);

    useEffect(() => {
        // get labels
        readCalendarLabels()
            .then((res: any) => {
                setLabels(res.labelsList);
            })
        // get agents
        gRPCReadUsers()
            .then((res: any) => {
                setAgents(res.usersList);
            })
        if (fromTableView) {
            // get lists
            readListsByProjectId(projectId ?? "")
                .then((res: any) => {
                    setLists(res.listsList);
                })
        }

    }, [])

    return (
        <FormContainer
            onSubmit={onSubmit}
            columns={1}
            initialValues={initialValues}
        >
            <VStack
                gap={".5rem"}
                w={"100%"}
                bg={primaryWhite}
                p={".5rem"}
                border={`1px solid #e8e8e8`}
                borderRadius={"3px"}
                justifyContent={"flex-start"}
                alignItems={"stretch"}
            >
                <HStack>
                    <FormInput
                        name={"title"}
                        placeholder={"task title"}
                        label="task title"
                        isRequired={true}
                    />
                    <Spacer />
                </HStack>
                <SimpleGrid columns={gridColumns} spacing={gridSpacing}>
                    <FormInputDate
                        name={"dueDate"}
                        isRequired={true}
                        placeholder={strings.due_date}
                    />
                    <FormMultiSelect
                        name={"labelsList"}
                        setValue={setSelectedLabels}
                        placeholder={strings.label}
                        defaultValue={initialValues?.label?.map((lb: any) => {
                            return lb;
                        })}
                    >
                        {(labels ?? []).map((label: any) => (
                            <Select.Option key={label.id} value={label.id}>
                                {label.title}
                            </Select.Option>
                        ))}
                    </FormMultiSelect>
                    <FormSelect
                        name="scope">
                        <Select.Option value="agent" >
                            {"agent"}
                        </Select.Option>
                        <Select.Option value="organisation">
                            {"organisation"}
                        </Select.Option>
                    </FormSelect>
                    {
                        fromTableView && <FormSelect name="listId" placeholder="Select List">
                            {(lists ?? []).map((list: any) => (
                                <Select.Option key={list.id} value={list.id}>
                                    {list.title}
                                </Select.Option>
                            ))}
                        </FormSelect>
                    }
                    <FormMultiSelect
                        name={"assigneesList"}
                        isRequired={true}
                        setValue={setSelectedAgents}
                        placeholder={strings.assigned_to}
                        defaultValue={initialValues?.assigneesList?.map((agent: any) => {
                            return agent;
                        })}
                    >
                        {(agents ?? []).map((agent: any) => (
                            <Select.Option key={agent.id} value={agent.id}>
                                {`${agent.firstName ?? "-"} ${agent.lastName ?? "-"}`}
                            </Select.Option>
                        ))}
                    </FormMultiSelect>
                </SimpleGrid>

                <Text>{strings.notes}</Text>
                <FormTextArea
                    name={"note"}
                    rows={5}
                    placeholder={"notes"}
                />

            </VStack>

            <Flex justify={"flex-end"} align={"center"} mb={"1rem"}>
                <FormCancelButton onClick={onClose} />
                <CustomButton type={"submit"} isLoading={loading}>
                    <MdSave />
                    {title}
                </CustomButton>
            </Flex>
        </FormContainer>
    );
};

export default TaskForm;
