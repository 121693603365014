/**
 * @fileoverview gRPC-Web generated client stub for da.calendar.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: project.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.calendar = {};
proto.da.calendar.proto = require('./project_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.ProjectServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.ProjectServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.CreateProjectRequest,
 *   !proto.da.calendar.proto.CreateProjectResponse>}
 */
const methodDescriptor_ProjectService_CreateProject = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ProjectService/CreateProject',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.CreateProjectRequest,
  proto.da.calendar.proto.CreateProjectResponse,
  /**
   * @param {!proto.da.calendar.proto.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.CreateProjectResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.CreateProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.CreateProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ProjectServiceClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_CreateProject,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.CreateProjectResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ProjectServicePromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_CreateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadProjectRequest,
 *   !proto.da.calendar.proto.ReadProjectResponse>}
 */
const methodDescriptor_ProjectService_ReadProject = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ProjectService/ReadProject',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadProjectRequest,
  proto.da.calendar.proto.ReadProjectResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadProjectResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ProjectServiceClient.prototype.readProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/ReadProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ReadProject,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadProjectResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ProjectServicePromiseClient.prototype.readProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/ReadProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ReadProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.UpdateProjectRequest,
 *   !proto.da.calendar.proto.UpdateProjectResponse>}
 */
const methodDescriptor_ProjectService_UpdateProject = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ProjectService/UpdateProject',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.UpdateProjectRequest,
  proto.da.calendar.proto.UpdateProjectResponse,
  /**
   * @param {!proto.da.calendar.proto.UpdateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.UpdateProjectResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.UpdateProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.UpdateProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ProjectServiceClient.prototype.updateProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_UpdateProject,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.UpdateProjectResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ProjectServicePromiseClient.prototype.updateProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_UpdateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.DeleteProjectRequest,
 *   !proto.da.calendar.proto.DeleteProjectResponse>}
 */
const methodDescriptor_ProjectService_DeleteProject = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ProjectService/DeleteProject',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.DeleteProjectRequest,
  proto.da.calendar.proto.DeleteProjectResponse,
  /**
   * @param {!proto.da.calendar.proto.DeleteProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.DeleteProjectResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.DeleteProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.DeleteProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.DeleteProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ProjectServiceClient.prototype.deleteProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_DeleteProject,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.DeleteProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.DeleteProjectResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ProjectServicePromiseClient.prototype.deleteProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_DeleteProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadProjectsRequest,
 *   !proto.da.calendar.proto.ReadProjectsResponse>}
 */
const methodDescriptor_ProjectService_ReadProjects = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ProjectService/ReadProjects',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadProjectsRequest,
  proto.da.calendar.proto.ReadProjectsResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ProjectServiceClient.prototype.readProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/ReadProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ReadProjects,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ProjectServicePromiseClient.prototype.readProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/ReadProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ReadProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ArchiveProjectRequest,
 *   !proto.da.calendar.proto.ArchiveProjectResponse>}
 */
const methodDescriptor_ProjectService_ArchiveProject = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.ProjectService/ArchiveProject',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ArchiveProjectRequest,
  proto.da.calendar.proto.ArchiveProjectResponse,
  /**
   * @param {!proto.da.calendar.proto.ArchiveProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ArchiveProjectResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ArchiveProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ArchiveProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ArchiveProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.ProjectServiceClient.prototype.archiveProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/ArchiveProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ArchiveProject,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ArchiveProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ArchiveProjectResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.ProjectServicePromiseClient.prototype.archiveProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.ProjectService/ArchiveProject',
      request,
      metadata || {},
      methodDescriptor_ProjectService_ArchiveProject);
};


module.exports = proto.da.calendar.proto;

