import { Flex } from "@chakra-ui/react";
import ListBoardStage from "./ListBoardStage";
import AddList from "./AddList";
import { useParams } from "react-router-dom";

interface IListBoardProps {
    tasks: any[]
    lists: any[]
}

function ListBoardView({ tasks, lists }: Readonly<IListBoardProps>) {
    const { id } = useParams()


    const filterTasks = (listId: string) => {
        return tasks.filter((task) => task.listId === listId);
    }

    return (
        <Flex gap={".5rem"} justifyContent={"flex-start"} style={{ overflow: "scroll" }}>
            {lists?.map((stage: any) => {
                return <ListBoardStage stage={stage} tasks={filterTasks(stage.id)} key={stage?.id} />;
            })}
            <AddList projectId={id ?? ""} />
        </Flex>
    );
}

export default ListBoardView;
