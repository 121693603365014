/**
 * @fileoverview gRPC-Web generated client stub for da.calendar.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: event.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.calendar = {};
proto.da.calendar.proto = require('./event_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.EventServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.EventServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.CreateEventRequest,
 *   !proto.da.calendar.proto.CreateEventResponse>}
 */
const methodDescriptor_EventService_CreateEvent = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.EventService/CreateEvent',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.CreateEventRequest,
  proto.da.calendar.proto.CreateEventResponse,
  /**
   * @param {!proto.da.calendar.proto.CreateEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.CreateEventResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.CreateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.CreateEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.CreateEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.EventServiceClient.prototype.createEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.EventService/CreateEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_CreateEvent,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.CreateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.CreateEventResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.EventServicePromiseClient.prototype.createEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.EventService/CreateEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_CreateEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadEventRequest,
 *   !proto.da.calendar.proto.ReadEventResponse>}
 */
const methodDescriptor_EventService_ReadEvent = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.EventService/ReadEvent',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadEventRequest,
  proto.da.calendar.proto.ReadEventResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadEventResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.EventServiceClient.prototype.readEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.EventService/ReadEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_ReadEvent,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadEventResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.EventServicePromiseClient.prototype.readEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.EventService/ReadEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_ReadEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.UpdateEventRequest,
 *   !proto.da.calendar.proto.UpdateEventResponse>}
 */
const methodDescriptor_EventService_UpdateEvent = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.EventService/UpdateEvent',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.UpdateEventRequest,
  proto.da.calendar.proto.UpdateEventResponse,
  /**
   * @param {!proto.da.calendar.proto.UpdateEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.UpdateEventResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.UpdateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.UpdateEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.UpdateEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.EventServiceClient.prototype.updateEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.EventService/UpdateEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_UpdateEvent,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.UpdateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.UpdateEventResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.EventServicePromiseClient.prototype.updateEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.EventService/UpdateEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_UpdateEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadEventsRequest,
 *   !proto.da.calendar.proto.ReadEventsResponse>}
 */
const methodDescriptor_EventService_ReadEvents = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.EventService/ReadEvents',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadEventsRequest,
  proto.da.calendar.proto.ReadEventsResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadEventsResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.EventServiceClient.prototype.readEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.EventService/ReadEvents',
      request,
      metadata || {},
      methodDescriptor_EventService_ReadEvents,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadEventsResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.EventServicePromiseClient.prototype.readEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.EventService/ReadEvents',
      request,
      metadata || {},
      methodDescriptor_EventService_ReadEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.DeleteEventRequest,
 *   !proto.da.calendar.proto.DeleteEventResponse>}
 */
const methodDescriptor_EventService_DeleteEvent = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.EventService/DeleteEvent',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.DeleteEventRequest,
  proto.da.calendar.proto.DeleteEventResponse,
  /**
   * @param {!proto.da.calendar.proto.DeleteEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.DeleteEventResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.DeleteEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.DeleteEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.DeleteEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.EventServiceClient.prototype.deleteEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.EventService/DeleteEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_DeleteEvent,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.DeleteEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.DeleteEventResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.EventServicePromiseClient.prototype.deleteEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.EventService/DeleteEvent',
      request,
      metadata || {},
      methodDescriptor_EventService_DeleteEvent);
};


module.exports = proto.da.calendar.proto;

