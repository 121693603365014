/**
 * @fileoverview gRPC-Web generated client stub for da.calendar.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: task.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var calendar_label_pb = require('./calendar_label_pb.js')
const proto = {};
proto.da = {};
proto.da.calendar = {};
proto.da.calendar.proto = require('./task_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.TaskServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.calendar.proto.TaskServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.CreateTaskRequest,
 *   !proto.da.calendar.proto.CreateTaskResponse>}
 */
const methodDescriptor_TaskService_CreateTask = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.TaskService/CreateTask',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.CreateTaskRequest,
  proto.da.calendar.proto.CreateTaskResponse,
  /**
   * @param {!proto.da.calendar.proto.CreateTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.CreateTaskResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.CreateTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.CreateTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.CreateTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.TaskServiceClient.prototype.createTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.TaskService/CreateTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_CreateTask,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.CreateTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.CreateTaskResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.TaskServicePromiseClient.prototype.createTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.TaskService/CreateTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_CreateTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadTaskRequest,
 *   !proto.da.calendar.proto.ReadTaskResponse>}
 */
const methodDescriptor_TaskService_ReadTask = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.TaskService/ReadTask',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadTaskRequest,
  proto.da.calendar.proto.ReadTaskResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadTaskResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.TaskServiceClient.prototype.readTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.TaskService/ReadTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_ReadTask,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadTaskResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.TaskServicePromiseClient.prototype.readTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.TaskService/ReadTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_ReadTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.UpdateTaskRequest,
 *   !proto.da.calendar.proto.UpdateTaskResponse>}
 */
const methodDescriptor_TaskService_UpdateTask = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.TaskService/UpdateTask',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.UpdateTaskRequest,
  proto.da.calendar.proto.UpdateTaskResponse,
  /**
   * @param {!proto.da.calendar.proto.UpdateTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.UpdateTaskResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.UpdateTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.UpdateTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.UpdateTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.TaskServiceClient.prototype.updateTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.TaskService/UpdateTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_UpdateTask,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.UpdateTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.UpdateTaskResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.TaskServicePromiseClient.prototype.updateTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.TaskService/UpdateTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_UpdateTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadTasksRequest,
 *   !proto.da.calendar.proto.ReadTasksResponse>}
 */
const methodDescriptor_TaskService_ReadTasks = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.TaskService/ReadTasks',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadTasksRequest,
  proto.da.calendar.proto.ReadTasksResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadTasksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadTasksResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.TaskServiceClient.prototype.readTasks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.TaskService/ReadTasks',
      request,
      metadata || {},
      methodDescriptor_TaskService_ReadTasks,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadTasksResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.TaskServicePromiseClient.prototype.readTasks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.TaskService/ReadTasks',
      request,
      metadata || {},
      methodDescriptor_TaskService_ReadTasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.DeleteTaskRequest,
 *   !proto.da.calendar.proto.DeleteTaskResponse>}
 */
const methodDescriptor_TaskService_DeleteTask = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.TaskService/DeleteTask',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.DeleteTaskRequest,
  proto.da.calendar.proto.DeleteTaskResponse,
  /**
   * @param {!proto.da.calendar.proto.DeleteTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.DeleteTaskResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.DeleteTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.DeleteTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.DeleteTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.TaskServiceClient.prototype.deleteTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.TaskService/DeleteTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_DeleteTask,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.DeleteTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.DeleteTaskResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.TaskServicePromiseClient.prototype.deleteTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.TaskService/DeleteTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_DeleteTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.ReadTasksByListIdRequest,
 *   !proto.da.calendar.proto.ReadTasksResponse>}
 */
const methodDescriptor_TaskService_ReadTasksByListId = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.TaskService/ReadTasksByListId',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.ReadTasksByListIdRequest,
  proto.da.calendar.proto.ReadTasksResponse,
  /**
   * @param {!proto.da.calendar.proto.ReadTasksByListIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.ReadTasksResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.ReadTasksByListIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.ReadTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.ReadTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.TaskServiceClient.prototype.readTasksByListId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.TaskService/ReadTasksByListId',
      request,
      metadata || {},
      methodDescriptor_TaskService_ReadTasksByListId,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.ReadTasksByListIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.ReadTasksResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.TaskServicePromiseClient.prototype.readTasksByListId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.TaskService/ReadTasksByListId',
      request,
      metadata || {},
      methodDescriptor_TaskService_ReadTasksByListId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.calendar.proto.UpdateTaskListRequest,
 *   !proto.da.calendar.proto.UpdateTaskListResponse>}
 */
const methodDescriptor_TaskService_UpdateTaskList = new grpc.web.MethodDescriptor(
  '/da.calendar.proto.TaskService/UpdateTaskList',
  grpc.web.MethodType.UNARY,
  proto.da.calendar.proto.UpdateTaskListRequest,
  proto.da.calendar.proto.UpdateTaskListResponse,
  /**
   * @param {!proto.da.calendar.proto.UpdateTaskListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.calendar.proto.UpdateTaskListResponse.deserializeBinary
);


/**
 * @param {!proto.da.calendar.proto.UpdateTaskListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.calendar.proto.UpdateTaskListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.calendar.proto.UpdateTaskListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.calendar.proto.TaskServiceClient.prototype.updateTaskList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.calendar.proto.TaskService/UpdateTaskList',
      request,
      metadata || {},
      methodDescriptor_TaskService_UpdateTaskList,
      callback);
};


/**
 * @param {!proto.da.calendar.proto.UpdateTaskListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.calendar.proto.UpdateTaskListResponse>}
 *     Promise that resolves to the response
 */
proto.da.calendar.proto.TaskServicePromiseClient.prototype.updateTaskList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.calendar.proto.TaskService/UpdateTaskList',
      request,
      metadata || {},
      methodDescriptor_TaskService_UpdateTaskList);
};


module.exports = proto.da.calendar.proto;

