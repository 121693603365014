import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { set, SubmitHandler } from "react-hook-form";
import { Link, Task } from "../../../gateway/gRPC/pb/task_pb";
import { createTask } from "../../../gateway/gRPC/Utils/CalendarMethods";
import { notify, putNodeItem } from "../../../gateway/Functions";
import { MdAdd } from "react-icons/md";
import CustomButton from "../../buttons/CustomButton";
import TaskForm from "./TaskForm";
import MyModal from "../../general/MyModal";
import strings from "../../../localization/main";
import { useDispatch } from "react-redux";
import { updateTables } from "../../../redux/actions/actions";
import moment from "moment-timezone";

interface AddTaskProps {
    listId?: string
    fromTicket?: boolean
    fromTableView?: boolean
    fromBoardView?: boolean
    ticketId?: string
    projectId?: string
}

const AddTask = ({ listId, fromTicket, fromTableView, fromBoardView, ticketId, projectId }: AddTaskProps) => {
    const { onClose, isOpen, onOpen } = useDisclosure();
    const [loading, setLoading] = useState(false)
    const [selectedLabels, setSelectedLabels] = useState<any[]>([]);
    const [selectedAgents, setSelectedAgents] = useState<any[]>([]);
    const [link, setLink] = useState<Link.AsObject>(new Link().toObject());
    const dispatch = useDispatch();

    const onSubmit: SubmitHandler<Task.AsObject> = (data: Task.AsObject) => {
        // setLoading(true)
        data.labelsList = selectedLabels;
        data.assigneesList = selectedAgents;
        if (fromBoardView) {
            data.listId = listId ?? "";
        }
        data.dueDate = moment(data.dueDate).format("YYYY-MM-DDTHH:mm:ssZ")
        

        if (fromTicket) {
            const updatedLink = { ...link, type: 2, uuid: ticketId ?? "" };
            data.linksList = [updatedLink];
        }

        console.log(data)


        createTask(data)
            .then((res: any) => {
                if (fromTicket) {
                    putNodeItem(`tickets/${ticketId}`, { task_id: res?.task?.id })
                        .then(() => {
                            notify("success",
                                "bottomLeft",
                                strings.success,
                                "Task Added to ticket Successfully")
                        });
                }
                dispatch(updateTables());
                notify("success",
                    "bottomLeft",
                    strings.success,
                    "Task Created Successfully")
            })
            .catch((err) => {
                notify("error",
                    "bottomLeft",
                    strings.error,
                    "Error Creating Task",)
            })
            .finally(() => {
                onClose();
                setSelectedAgents([]);
                setSelectedLabels([]);
                setLoading(false)
            })
        onClose();
    }

    return (
        <>
            <CustomButton onClick={onOpen} width={"10rem"}>
                <MdAdd /> {strings.add_new_task}
            </CustomButton>

            <MyModal
                size={"lg"}
                closeOnOverlayClick={false}
                disclosure={{ isOpen, onClose, onOpen }}
                scrollBehavior={"inside"}
                title={strings.add_new_task}
            >
                <TaskForm
                    onSubmit={onSubmit}
                    onClose={onClose}
                    title={strings.add_new_task}
                    loading={loading}
                    setSelectedLabels={setSelectedLabels}
                    setSelectedAgents={setSelectedAgents}
                    fromTableView={fromTableView}
                    projectId={projectId} />
            </MyModal>
        </>
    )
}

export default AddTask;
