import { RESET_EXTENSION_DIAL_STATE, SET_EXTENSION_DIAL_STATE } from "../actions/actions";

export default function ExtensionDialState(state: string = "", action: any) {
    switch (action.type) {
        case SET_EXTENSION_DIAL_STATE:
            return action.payload;
        case RESET_EXTENSION_DIAL_STATE:
            return "";
        default:
            return state;
    }
}
