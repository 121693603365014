import { Text, Box, HStack, Spacer, VStack } from "@chakra-ui/react";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { MdPeople } from "react-icons/md";
import { RiLayout4Fill, RiSettings5Fill } from "react-icons/ri";
import { AiFillProject } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { SubHeading } from "../../headings/Headings";
import MemembersTable from "../members/MembersTable";
import { readProjects } from "../../../gateway/gRPC/Utils/CalendarMethods";
import AddProject from "./AddProject";
import ListsTableView from "../lists/ListTableView";

const ProjectsView = () => {
    const [activeKey, setActiveKey] = useState("projects");
    const [page] = useState(1);
    const [projectTabs, setProjectTabs] = useState<any[]>([]);

    const { id } = useParams();
    const navigate = useNavigate();

    const initProject = (projectId: string) => {
        setActiveKey(projectId);
        navigate(`/apps/projects/${projectId}`);
    };

    const fetchProjects = async () => {
        try {
            const res = await readProjects({ page, perPage: 100 });
            const projectsList = res?.projectsList ?? [];
            if (projectsList.length > 0) {
                setActiveKey(id ?? projectsList[0]?.id);
                const tabs = projectsList.map((project) => ({
                    label: (
                        <Box onClick={() => initProject(project.id)} className="projects">
                            <Text w={"max-content"}>{project.title}</Text>
                        </Box>
                    ),
                    key: project.id,
                    children: <ListsTableView projectId={project.id} />
                }));
                setProjectTabs(tabs);
            }
        } catch (err) {
            console.error(err); // TODO: Notify user
        }
    };

    useEffect(() => {
        fetchProjects();
    }, [id]);

    return (
        <VStack
            w={"100%"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            spacing={".5rem"}
            className="projectsTabs"
        >
            <Tabs
                style={{ width: "100%", height: "calc(100vh - 7rem)" }}
                tabBarStyle={{
                    width: "200px",
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                }}
                defaultActiveKey={"tasks"}
                activeKey={activeKey}
                onChange={(key: string) => setActiveKey(key)}
                tabPosition={"left"}
                type={"card"}
                items={[
                    {
                        key: "projects",
                        label: (
                            <HStack w={"100%"}>
                                <AiFillProject />
                                <SubHeading title={"Projects"} />
                                <Spacer />
                                <AddProject />
                            </HStack>
                        ),
                        disabled: true,
                    },
                    ...projectTabs,
                    {
                        key: "members",
                        label: (
                            <HStack w={"100%"} pr={"1rem"} className="projectCustomTabs">
                                <MdPeople />
                                <SubHeading title={"Members"} />
                            </HStack>
                        ),
                        children: <MemembersTable projectId={id ?? ""} />,
                        disabled: true,
                    },
                    {
                        key: "views",
                        label: (
                            <HStack w={"100%"} pr={"1rem"} className="projectCustomTabs">
                                <RiLayout4Fill />
                                <SubHeading title={"Views"} />
                            </HStack>
                        ),
                        disabled: true,
                    },
                    {
                        key: "settings",
                        label: (
                            <HStack w={"100%"} pr={"1rem"} className="projectCustomTabs">
                                <RiSettings5Fill />
                                <SubHeading title={"Settings"} />
                            </HStack>
                        ),
                        disabled: true,
                    },
                ]}
            />
        </VStack>
    );
};

export default ProjectsView;