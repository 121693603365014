import NotFoundMascot from "../../../assets/NotFoundMascot"

interface IMemembersTableProps {
    projectId: string
}

const MemembersTable = ({ projectId }: IMemembersTableProps) => {
    return (
        <> </>
    )
}

export default MemembersTable