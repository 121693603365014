import { MdAdd } from "react-icons/md";
import CustomButton from "../../buttons/CustomButton";
import { useDisclosure } from "@chakra-ui/react";
import MyModal from "../../general/MyModal";
import ProjectForm from "./ProjectForm";
import { SubmitHandler } from "react-hook-form";
import { Project } from "../../../gateway/gRPC/pb/project_pb";
import { createProject } from "../../../gateway/gRPC/Utils/CalendarMethods";
import { useState } from "react";
import { notify } from "../../../gateway/Functions";
import { useDispatch } from "react-redux";
import { updateTables } from "../../../redux/actions/actions";

const AddProject = () => {
    const { onClose, isOpen, onOpen } = useDisclosure();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit: SubmitHandler<Project.AsObject> = (data: Project.AsObject) => {
        setLoading(true)
        createProject(data)
            .then(() => {
                notify("success",
                    "bottomLeft",
                    "Project Created Successfully",
                    "Project Created Successfully")
                dispatch(updateTables());
            })
            .catch((err) => {
                notify("error",
                    "bottomLeft",
                    "Error Creating Project",
                    err.message)
            })
            .finally(() => {
                onClose();
                setLoading(false)
            })
        onClose();
    }

    return (
        <>
            <CustomButton onClick={onOpen}>
                <MdAdd />
            </CustomButton>

            <MyModal
                size={"lg"}
                closeOnOverlayClick={false}
                disclosure={{ isOpen, onClose, onOpen }}
                scrollBehavior={"inside"}
                title={"Add Project"}
            >
                <ProjectForm
                    onSubmit={onSubmit}
                    onClose={onClose}
                    title={"Add Project"}
                    loading={loading}
                />

            </MyModal>
        </>
    )
}

export default AddProject;
