// source: calendar_label.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.da.calendar.proto.CalendarLabel', null, global);
goog.exportSymbol('proto.da.calendar.proto.CreateCalendarLabelRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.CreateCalendarLabelResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.DeleteCalendarLabelRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.DeleteCalendarLabelResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadCalendarLabelRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadCalendarLabelResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadCalendarLabelsRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.ReadCalendarLabelsResponse', null, global);
goog.exportSymbol('proto.da.calendar.proto.UpdateCalendarLabelRequest', null, global);
goog.exportSymbol('proto.da.calendar.proto.UpdateCalendarLabelResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.CalendarLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.CalendarLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.CalendarLabel.displayName = 'proto.da.calendar.proto.CalendarLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.CreateCalendarLabelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.CreateCalendarLabelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.CreateCalendarLabelRequest.displayName = 'proto.da.calendar.proto.CreateCalendarLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.CreateCalendarLabelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.CreateCalendarLabelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.CreateCalendarLabelResponse.displayName = 'proto.da.calendar.proto.CreateCalendarLabelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadCalendarLabelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ReadCalendarLabelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadCalendarLabelRequest.displayName = 'proto.da.calendar.proto.ReadCalendarLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadCalendarLabelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ReadCalendarLabelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadCalendarLabelResponse.displayName = 'proto.da.calendar.proto.ReadCalendarLabelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.ReadCalendarLabelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadCalendarLabelsRequest.displayName = 'proto.da.calendar.proto.ReadCalendarLabelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.calendar.proto.ReadCalendarLabelsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.calendar.proto.ReadCalendarLabelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.ReadCalendarLabelsResponse.displayName = 'proto.da.calendar.proto.ReadCalendarLabelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.UpdateCalendarLabelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.UpdateCalendarLabelRequest.displayName = 'proto.da.calendar.proto.UpdateCalendarLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.UpdateCalendarLabelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.UpdateCalendarLabelResponse.displayName = 'proto.da.calendar.proto.UpdateCalendarLabelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.DeleteCalendarLabelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.DeleteCalendarLabelRequest.displayName = 'proto.da.calendar.proto.DeleteCalendarLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.calendar.proto.DeleteCalendarLabelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.calendar.proto.DeleteCalendarLabelResponse.displayName = 'proto.da.calendar.proto.DeleteCalendarLabelResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.CalendarLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.CalendarLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.CalendarLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CalendarLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.CalendarLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.CalendarLabel;
  return proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.CalendarLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.CalendarLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.CalendarLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.CalendarLabel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.CalendarLabel} returns this
 */
proto.da.calendar.proto.CalendarLabel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.da.calendar.proto.CalendarLabel.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.CalendarLabel} returns this
 */
proto.da.calendar.proto.CalendarLabel.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.da.calendar.proto.CalendarLabel.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.CalendarLabel} returns this
 */
proto.da.calendar.proto.CalendarLabel.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.CreateCalendarLabelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.CreateCalendarLabelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && proto.da.calendar.proto.CalendarLabel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.CreateCalendarLabelRequest}
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.CreateCalendarLabelRequest;
  return proto.da.calendar.proto.CreateCalendarLabelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.CreateCalendarLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.CreateCalendarLabelRequest}
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.CalendarLabel;
      reader.readMessage(value,proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.CreateCalendarLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.CreateCalendarLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarLabel label = 1;
 * @return {?proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.prototype.getLabel = function() {
  return /** @type{?proto.da.calendar.proto.CalendarLabel} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.CalendarLabel, 1));
};


/**
 * @param {?proto.da.calendar.proto.CalendarLabel|undefined} value
 * @return {!proto.da.calendar.proto.CreateCalendarLabelRequest} returns this
*/
proto.da.calendar.proto.CreateCalendarLabelRequest.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.CreateCalendarLabelRequest} returns this
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.CreateCalendarLabelRequest.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.CreateCalendarLabelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.CreateCalendarLabelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && proto.da.calendar.proto.CalendarLabel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.CreateCalendarLabelResponse}
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.CreateCalendarLabelResponse;
  return proto.da.calendar.proto.CreateCalendarLabelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.CreateCalendarLabelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.CreateCalendarLabelResponse}
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.CalendarLabel;
      reader.readMessage(value,proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.CreateCalendarLabelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.CreateCalendarLabelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarLabel label = 1;
 * @return {?proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.prototype.getLabel = function() {
  return /** @type{?proto.da.calendar.proto.CalendarLabel} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.CalendarLabel, 1));
};


/**
 * @param {?proto.da.calendar.proto.CalendarLabel|undefined} value
 * @return {!proto.da.calendar.proto.CreateCalendarLabelResponse} returns this
*/
proto.da.calendar.proto.CreateCalendarLabelResponse.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.CreateCalendarLabelResponse} returns this
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.CreateCalendarLabelResponse.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadCalendarLabelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadCalendarLabelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelRequest}
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadCalendarLabelRequest;
  return proto.da.calendar.proto.ReadCalendarLabelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelRequest}
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadCalendarLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelRequest} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadCalendarLabelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadCalendarLabelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && proto.da.calendar.proto.CalendarLabel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelResponse}
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadCalendarLabelResponse;
  return proto.da.calendar.proto.ReadCalendarLabelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelResponse}
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.CalendarLabel;
      reader.readMessage(value,proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadCalendarLabelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarLabel label = 1;
 * @return {?proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.prototype.getLabel = function() {
  return /** @type{?proto.da.calendar.proto.CalendarLabel} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.CalendarLabel, 1));
};


/**
 * @param {?proto.da.calendar.proto.CalendarLabel|undefined} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelResponse} returns this
*/
proto.da.calendar.proto.ReadCalendarLabelResponse.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelResponse} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.ReadCalendarLabelResponse.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadCalendarLabelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsRequest}
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadCalendarLabelsRequest;
  return proto.da.calendar.proto.ReadCalendarLabelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsRequest}
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadCalendarLabelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsRequest} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 per_page = 2;
 * @return {number}
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsRequest} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.ReadCalendarLabelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.da.calendar.proto.CalendarLabel.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsResponse}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.ReadCalendarLabelsResponse;
  return proto.da.calendar.proto.ReadCalendarLabelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsResponse}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.CalendarLabel;
      reader.readMessage(value,proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.ReadCalendarLabelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.ReadCalendarLabelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated CalendarLabel labels = 1;
 * @return {!Array<!proto.da.calendar.proto.CalendarLabel>}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.da.calendar.proto.CalendarLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.calendar.proto.CalendarLabel, 1));
};


/**
 * @param {!Array<!proto.da.calendar.proto.CalendarLabel>} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsResponse} returns this
*/
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.calendar.proto.CalendarLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.calendar.proto.CalendarLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsResponse} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsResponse} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 page = 3;
 * @return {number}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsResponse} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 per_page = 4;
 * @return {number}
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.calendar.proto.ReadCalendarLabelsResponse} returns this
 */
proto.da.calendar.proto.ReadCalendarLabelsResponse.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.UpdateCalendarLabelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && proto.da.calendar.proto.CalendarLabel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelRequest}
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.UpdateCalendarLabelRequest;
  return proto.da.calendar.proto.UpdateCalendarLabelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelRequest}
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.CalendarLabel;
      reader.readMessage(value,proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.UpdateCalendarLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarLabel label = 1;
 * @return {?proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.prototype.getLabel = function() {
  return /** @type{?proto.da.calendar.proto.CalendarLabel} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.CalendarLabel, 1));
};


/**
 * @param {?proto.da.calendar.proto.CalendarLabel|undefined} value
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelRequest} returns this
*/
proto.da.calendar.proto.UpdateCalendarLabelRequest.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelRequest} returns this
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.UpdateCalendarLabelRequest.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.UpdateCalendarLabelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && proto.da.calendar.proto.CalendarLabel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelResponse}
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.UpdateCalendarLabelResponse;
  return proto.da.calendar.proto.UpdateCalendarLabelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelResponse}
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.calendar.proto.CalendarLabel;
      reader.readMessage(value,proto.da.calendar.proto.CalendarLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.UpdateCalendarLabelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.UpdateCalendarLabelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.calendar.proto.CalendarLabel.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarLabel label = 1;
 * @return {?proto.da.calendar.proto.CalendarLabel}
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.prototype.getLabel = function() {
  return /** @type{?proto.da.calendar.proto.CalendarLabel} */ (
    jspb.Message.getWrapperField(this, proto.da.calendar.proto.CalendarLabel, 1));
};


/**
 * @param {?proto.da.calendar.proto.CalendarLabel|undefined} value
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelResponse} returns this
*/
proto.da.calendar.proto.UpdateCalendarLabelResponse.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.calendar.proto.UpdateCalendarLabelResponse} returns this
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.calendar.proto.UpdateCalendarLabelResponse.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.DeleteCalendarLabelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.DeleteCalendarLabelRequest}
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.DeleteCalendarLabelRequest;
  return proto.da.calendar.proto.DeleteCalendarLabelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.DeleteCalendarLabelRequest}
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.DeleteCalendarLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.DeleteCalendarLabelRequest} returns this
 */
proto.da.calendar.proto.DeleteCalendarLabelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.calendar.proto.DeleteCalendarLabelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.calendar.proto.DeleteCalendarLabelResponse}
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.calendar.proto.DeleteCalendarLabelResponse;
  return proto.da.calendar.proto.DeleteCalendarLabelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.calendar.proto.DeleteCalendarLabelResponse}
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.calendar.proto.DeleteCalendarLabelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.calendar.proto.DeleteCalendarLabelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.calendar.proto.DeleteCalendarLabelResponse} returns this
 */
proto.da.calendar.proto.DeleteCalendarLabelResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.da.calendar.proto);
