import { SessionState } from "sip.js";
import {
	ActiveCallState,
	ActiveCallStateAction,
	ChatdeskFilterType,
	CountryCodeData,
	PhoneStateType,
	RightBarState,
	UserSip,
} from "../../types/DataTypes";
import { User } from "../../gateway/gRPC/pb/user_pb";
import { RefreshTokenResponse } from "../../gateway/gRPC/pb/auth_pb";

export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const EXPAND_RIGHT_BAR = "EXPAND_RIGHT_BAR";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const TOGGLE_CALL_STATS = "TOGGLE_CALL_STATS";
export const SET_OUTBOUND_CALLS = "SET_OUTBOUND_CALLS";
export const SET_INBOUND_CALLS = "SET_INBOUND_CALLS";
export const SET_AGENT_RANKING = "SET_AGENT_RANKING";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_SETTINGS_STATE = "SET_SETTINGS_STATE";
export const SET_ON_BREAK = "SET_ON_BREAK";
export const SHOW_PHONE_SETTINGS = "SHOW_PHONE_SETTINGS";
export const SET_GLOBAL_DIAL_STATE = "SET_GLOBAL_DIAL_STATE";
export const RESET_GLOBAL_DIAL_STATE = "RESET_GLOBAL_DIAL_STATE";
export const SET_EXTENSION_DIAL_STATE = "SET_EXTENSION_DIAL_STATE";
export const RESET_EXTENSION_DIAL_STATE = "RESET_EXTENSION_DIAL_STATE";
export const SET_CALL_DISPOSITION = "SET_CALL_DISPOSITION";
export const OPEN_DISPOSITION = "OPEN_DISPOSITION";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const TOKENIZE = "TOKENIZE";
export const DE_TOKENIZE = "DE_TOKENIZE";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SET_SELECTED_TICKET = "SET_SELECTED_TICKET";
export const UPDATE_TICKET_THREADS = "UPDATE_TICKET_THREADS";
export const UPDATE_TABLES = "UPDATE_TABLES";
export const SET_AGENT_REGISTER_STATE = "SET_AGENT_REGISTER_STATE";
export const ADD_CALL_TO_QUEUE = "ADD_CALL_TO_QUEUE";
export const SET_PHONE_CONNECT = "SET_PHONE_CONNECT";
export const SET_ACTIVE_CALL = "SET_ACTIVE_CALL";
export const REMOVE_ACTIVE_CALL = "REMOVE_ACTIVE_CALL";
export const UPDATE_SESSION_STATE = "UPDATE_SESSION_STATE";
export const ADD_PHONE_INSTANCE = "ADD_PHONE_INSTANCE";
export const REMOVE_PHONE_INSTANCE = "REMOVE_PHONE_INSTANCE";
export const NOTIFICATIONS_COUNT = "NOTIFICATIONS_COUNT";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const REMOVE_USER_PERMISSIONS = "REMOVE_USER_PERMISSIONS";
export const SELECT_FLOW = "SELECT_FLOW";
export const RESET_FLOW = "RESET_FLOW";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_USER_SIP = "SET_USER_SIP";
export const REMOVE_USER_SIP = "REMOVE_USER_SIP";
export const SET_ACCESS_TOKENS = "SET_ACCESS_TOKENS";
export const RESET_ACCESS_TOKENS = "RESET_ACCESS_TOKENS";
export const CHATDESK_FILTERS = "CHATDESK_FILTERS";
export const RESET_CHATDESK_FILTERS = "RESET_CHATDESK_FILTERS";
export const SET_CHATDESK_SEARCH = "SET_CHATDESK_SEARCH";

export const collapseSideBar = () => {
	return {
		type: COLLAPSE_SIDEBAR,
	};
};
export const setActiveMenu = (menu: string) => {
	return {
		type: SET_ACTIVE_MENU,
		payload: menu,
	};
};
export const setSelectedAccount = (account: number) => {
	return {
		type: SET_SELECTED_ACCOUNT,
		payload: account,
	};
};
export const setSelectedTicket = (account: string) => {
	return {
		type: SET_SELECTED_TICKET,
		payload: account,
	};
};

export const setUserRoles = (userRoles: string[]) => {
	return {
		type: SET_USER_ROLES,
		payload: userRoles,
	};
};

export const expandRightBar = (state: RightBarState) => {
	return {
		type: EXPAND_RIGHT_BAR,
		payload: state,
	};
};
export const setLoggedIn = (state: boolean) => {
	return {
		type: SET_LOGGED_IN,
		payload: state,
	};
};
export const toggleCallStats = (state: any) => {
	return {
		type: TOGGLE_CALL_STATS,
		payload: state,
	};
};
export const setOutboundCalls = (calls: any) => {
	return {
		type: SET_OUTBOUND_CALLS,
		calls,
	};
};
export const setInboundCalls = (calls: any) => {
	return {
		type: SET_INBOUND_CALLS,
		calls,
	};
};
export const setAgentRanking = (ranks: any) => {
	return {
		type: SET_AGENT_RANKING,
		ranks,
	};
};
export const setProfile = (profile: User.AsObject) => {
	return {
		type: SET_USER_PROFILE,
		payload: profile,
	};
};
export const resetProfile = () => {
	return {
		type: SET_USER_PROFILE,
		payload: new User().toObject(),
	};
};
export const setSettingsState = (state: any) => {
	return {
		type: SET_SETTINGS_STATE,
		state,
	};
};
export const setOnBreak = (state: any) => {
	return {
		type: SET_ON_BREAK,
		payload: state,
	};
};
export const setShowPhoneSettings = () => {
	return {
		type: SHOW_PHONE_SETTINGS,
	};
};
export const setDialState = (dialState: string) => {
	return {
		type: SET_GLOBAL_DIAL_STATE,
		payload: dialState,
	};
};
export const resetDialState = () => {
	return {
		type: RESET_GLOBAL_DIAL_STATE,
	};
};
export const setExtensionDialState = (dialState: string) => {
	return {
		type: SET_EXTENSION_DIAL_STATE,
		payload: dialState,
	};
};
export const resetExtensionDialState = () => {
	return {
		type: RESET_EXTENSION_DIAL_STATE,
	};
};
export const userLogin = (userObj: User.AsObject, accessToken: string) => {
	return {
		type: USER_LOGIN,
		payload: {
			user: userObj,
			accessToken: accessToken,
		},
	};
};
export const userLogout = () => {
	return {
		type: USER_LOGOUT,
		payload: {
			email: null,
			username: null,
			firstName: null,
			lastName: null,
			phoneNumber: null,
			photoURL: null,
			accessToken: null,
			id: null,
			branchId: null,
			status: null,
			role: null,
		},
	};
};
export const userSip = (sip: UserSip) => {
	return {
		type: SET_USER_SIP,
		payload: sip,
	};
};
export const removeUserSip = () => {
	return {
		type: REMOVE_USER_SIP,
		payload: null,
	};
};
export const tokenize = (tn: any) => {
	return {
		type: TOKENIZE,
		payload: {
			tn: tn,
		},
	};
};
export const deTokenize = () => {
	return {
		type: DE_TOKENIZE,
	};
};
export const setLogOut = () => {
	return {
		type: SIGN_OUT,
	};
};
export const updateTicketThreads = () => {
	return {
		type: UPDATE_TICKET_THREADS,
	};
};
export const updateTables = () => {
	return {
		type: UPDATE_TABLES,
	};
};
export const setPhoneState = (state: PhoneStateType) => {
	return {
		type: SET_AGENT_REGISTER_STATE,
		payload: state,
	};
};
export const addCallToQueue = (call: any) => {
	return {
		type: ADD_CALL_TO_QUEUE,
		payload: call,
	};
};
export const setPhoneConnect = (state: boolean) => {
	return {
		type: SET_PHONE_CONNECT,
		payload: state,
	};
};
export const setActiveCall = (
	state: ActiveCallState
): ActiveCallStateAction => {
	const { call, notify, direction } = state;
	return {
		type: SET_ACTIVE_CALL,
		payload: {
			call: call,
			notify: notify,
			direction: direction,
			sessionState: null,
		},
	};
};
export const removeActiveCall = (): ActiveCallStateAction => {
	return {
		type: REMOVE_ACTIVE_CALL,
		payload: {
			call: null,
			notify: false,
			direction: "",
			sessionState: SessionState.Initial,
		},
	};
};
export const updateSessionState = (state: SessionState) => {
	return {
		type: UPDATE_SESSION_STATE,
		payload: {
			sessionState: state,
		},
	};
};
export const addPhoneInstances = (instance: string) => {
	return {
		type: ADD_PHONE_INSTANCE,
		payload: instance,
	};
};
export const removePhoneInstances = (instance: string) => {
	return {
		type: REMOVE_PHONE_INSTANCE,
		payload: instance,
	};
};
export const setNotificationsCount = () => {
	return {
		type: NOTIFICATIONS_COUNT,
	};
};
export const setUserLocation = (payload: CountryCodeData) => {
	return {
		type: SET_USER_LOCATION,
		payload: payload,
	};
};

export const setUserPermissions = (payload: any) => {
	return {
		type: SET_USER_PERMISSIONS,
		payload: payload,
	};
};
export const removeUserPermissions = () => {
	return {
		type: REMOVE_USER_PERMISSIONS,
		payload: null,
	};
};
export const selectFlow = (flowId: string) => {
	return {
		type: SELECT_FLOW,
		payload: flowId,
	};
};

export const resetFlow = () => {
	return {
		type: RESET_FLOW,
	};
};

export const setAppLanguage = (language: string) => {
	return {
		type: SET_LANGUAGE,
		payload: language,
	};
};

export const setTokens = (accesstokens: RefreshTokenResponse.AsObject) => {
	return {
		type: SET_ACCESS_TOKENS,
		payload: accesstokens,
	};
};

export const resetTokens = () => {
	return {
		type: RESET_ACCESS_TOKENS,
	};
};

export const chatdeskFilters = (payload: ChatdeskFilterType) => {
	return {
		type: CHATDESK_FILTERS,
		payload: payload,
	};
};

export const resetChatdeskFilters = () => {
	return {
		type: RESET_CHATDESK_FILTERS,
	};
};

export const setChatdeskSearch = (payload: string) => {
	return {
		type: SET_CHATDESK_SEARCH,
		payload: payload,
	};
};
