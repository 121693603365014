import { HStack } from "@chakra-ui/react";

type Type = "task" | "subtask";

interface Props<T> {
  agentIds: number[];
  task: T;
  type: Type;
}

const TaskAgents = <Task,>({ agentIds, task, type }: Props<Task>) => {
  return (
    <HStack
      spacing={0}
      gap={".5rem"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      {/* <Box>
				{agents.slice(0, 2).map((agent: User.AsObject, index: number) => (
					<Tooltip
						label={`${agent.firstName} 
            ${agent.lastName}`}
						key={agent.id}
						z-index={`${10 - index}`}
					>
						<Avatar
							cursor={"pointer"}
							size={"xs"}
							color={"gray.100"}
							bg={"gray.600"}
							name={`${agent.firstName || "User"} ${agent.lastName || "Name"
								}`}
							outline={`2px solid white`}
							ml={"-10px"}
							onClick={() => navigate(`/profile/${agent?.id}`)}
						/>
					</Tooltip>
				))}
				{agents.length > 0 && (
					<Avatar
						cursor={"pointer"}
						size={"xs"}
						color={"gray.100"}
						bg={"gray.600"}
						name={`${agents.length}`}
						outline={`2px solid white`}
						ml={"-10px"}
					/>
				)}

				<Menu key={menuId} closeOnSelect={false}>
					<MenuButton>
						<Tooltip label={strings.assign_agent}>
							<Avatar
								icon={<RiUserAddLine />}
								cursor={"pointer"}
								size={"xs"}
								outline={`2px solid white`}
								ml={"-5px"}
							/>
						</Tooltip>
					</MenuButton>
					<Portal>
						<MenuList>
							<MenuOptionGroup title={strings.assign_agent} type="checkbox">
								<Box maxH={"30vh"} overflow={"scroll"}>
									{users.map((user: any) => (
										<MenuItemOption
											key={user.id}
											value={user.id}
											isDisabled={agentIds?.includes(user.id)}
											onClick={() => handleSelected(user.id)}
											icon={
												<Avatar
													icon={<RiUserAddLine />}
													cursor={"pointer"}
													size={"xs"}
													outline={`2px solid white`}
												/>
											}
										>
											<HStack
												spacing={0}
												gap={".5rem"}
												w={"100%"}
												alignItems={"center"}
											>
												<Text textTransform={"capitalize"}>{`${user.first_name || user.username
													} ${user.last_name || user.email}`}</Text>
											</HStack>
										</MenuItemOption>
									))}
								</Box>
							</MenuOptionGroup>

							<MenuDivider />
							<MenuItem
								onClick={onSubmit}
								icon={
									<Avatar
										icon={<RiUserAddLine />}
										cursor={"pointer"}
										size={"xs"}
										outline={`2px solid white`}
									/>
								}
							>
								<Text fontSize={"10px"} fontWeight={"bold"}>
									{strings.assign_agent}
								</Text>
							</MenuItem>
						</MenuList>
					</Portal>
				</Menu>
			</Box> */}
    </HStack>
  );
};

export default TaskAgents;
