import { Flex, Spacer, VStack } from "@chakra-ui/react"
import { primaryWhite } from "../../../theme/theme"
import FormContainer from "../../forms/FormContainer/FormContainer"
import FormInput from "../../forms/FormContainer/FormInput"
import FormTextArea from "../../forms/FormContainer/FormTextArea"
import { MdSave } from "react-icons/md"
import CustomButton from "../../buttons/CustomButton"
import FormCancelButton from "../../buttons/FormCancelButton"

interface IListFormPRops {
    onSubmit: any
    onClose: any
    loading?: boolean
    initialValues?: any
    title: string
}

const ListForm = ({
    onSubmit,
    onClose,
    loading,
    initialValues,
    title,
}: IListFormPRops) => {
    return (
        <FormContainer
            onSubmit={onSubmit}
            columns={1}
            initialValues={initialValues}
        >
            <VStack
                gap={".5rem"}
                w={"100%"}
                bg={primaryWhite}
                p={".5rem"}
                border={`1px solid #e8e8e8`}
                borderRadius={"3px"}
                justifyContent={"flex-start"}
                alignItems={"stretch"}
            >
                <FormInput
                    name={"title"}
                    placeholder={"list title"}
                    label="list title"
                    isRequired={true}
                />
                <Spacer />
                <FormTextArea
                    name={"description"}
                    rows={5}
                    placeholder={"description"}
                />
            </VStack>

            <Flex justify={"flex-end"} align={"center"} mb={"1rem"}>
                <FormCancelButton onClick={onClose} />
                <CustomButton type={"submit"} isLoading={loading}>
                    <MdSave />
                    {title}
                </CustomButton>
            </Flex>
        </FormContainer>
    )
}

export default ListForm