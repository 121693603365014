import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { List } from "../../../gateway/gRPC/pb/list_pb";
import { updateTables } from "../../../redux/actions/actions";
import { notify } from "../../../gateway/Functions";
import strings from "../../../localization/main";
import { MdAdd } from "react-icons/md";
import CustomButton from "../../buttons/CustomButton";
import MyModal from "../../general/MyModal";
import ListForm from "./ListForm";
import { createList } from "../../../gateway/gRPC/Utils/CalendarMethods";

interface IAddListProps {
    projectId: string
}

const AddList = ({ projectId }: IAddListProps) => {
    const { onClose, isOpen, onOpen } = useDisclosure();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit: SubmitHandler<List.AsObject> = (data: List.AsObject) => {
        setLoading(true)
        data.projectId = projectId
        createList(data)
            .then(() => {
                dispatch(updateTables())
                notify("success",
                    "bottomLeft",
                    strings.success,
                    "List Created Successfully")
            })
            .catch((err) => {
                notify("error",
                    "bottomLeft",
                    "Error Creating List",
                    err.message)
            })
            .finally(() => {
                onClose();
                setLoading(false)
            })
    }

    return (
        <>
            <CustomButton onClick={onOpen} width={"10rem"}>
                <MdAdd /> {"Add List"}
            </CustomButton>

            <MyModal
                size={"lg"}
                closeOnOverlayClick={false}
                disclosure={{ isOpen, onClose, onOpen }}
                scrollBehavior={"inside"}
                title={"Add List"}
            >
                <ListForm
                    onSubmit={onSubmit}
                    onClose={onClose}
                    title="Add New List"
                    loading={loading}
                />


            </MyModal>
        </>
    )

}

export default AddList